// 
// 
// user.scss
//
// Place your own custom CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.
body {
    font-family: $bodycopy;
    font-weight: 300;
    font-style: normal;
    color: $navy;
    background: $beige;
    position: relative;
}

.h1, .h2, .h3, .h4, .h5,
h1, h2, h3, h4, h5 {
    color: $navy;
}

.bg-beige {
    background: $dbeige;
}
.bg-black {
    background: $black;
}

b, strong, p.bold {
    font-weight: 400;
}

p.italic, em, .italic {
    font-style: italic;
}

p {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 24px;
}
.h1, .h2, .h3,
h1, h2, h3 {
    font-family: $headings;
    font-weight: 300;
    font-style: normal;
}
h1 {
    font-size: 40px;
}
h2 {
    font-size: 30px;
}
h3 {
    font-size: 21px;
    font-weight: 400;
}

h5 {
    font-family: $bodycopy;
    font-weight: 700;
    font-style: normal;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 11px;
}
a {
    font-weight: 300;
    position:relative;

    &:hover {
        color: $lblue;
    }
   
}

p a {
    &:after {
        background: $lblue;
        width: 0px;
        height: 1px;
        position:absolute;
        top: 20px;
        content:'';
        left: 0px;
        -webkit-transition: width 0.5s ease-out; /* Safari prior 6.1 */
        transition: width 0.5s ease-out;
    }
    
    &:hover:after {
        width: 100%;
    }
}

.navbar-container {
    min-height: 0px !important;
    height: 0px !important;
}

nav.navbar {
    font-family: $bodycopy;
    text-transform: uppercase;
    padding: 20px;
    border-bottom: 0px;
    
    .navbar-brand .white-logo {display: inline-block; }
    .navbar-brand .default-logo {display: none;}
    .col-logo,
    .navbar-brand,
    #aboutnav-right,
    .menu2 {
        width: 235px;
    }
    .nav-link {
        font-weight: 700;
        font-size: 12px;
        letter-spacing: 1px;
        color: $white;
        text-shadow: 2px 2px 5px rgba($black, .3);
        padding: 1px 30px !important;
        &:last-child {
            padding-right: 0px !important;
        }
        &.active, &:hover {
            color: $lblue;
        }


    }
    .menu1 {display:none;}

    &.scrolled {
        background: $beige;
        border-bottom: 1px solid $dbeige;
        padding: 0px 25px;
        box-shadow: $box-shadow-sm;
        .menu1 {display:flex;}
        .navbar-brand .white-logo {display: none;}
        .navbar-brand .default-logo {display: inline-block;}
        .navbar-brand {width: 130px;}
        .nav-link {
            text-shadow: none;
            color: $navy;
            &.active, &:hover {
                color: $lblue;
            }
        }
        .navbar-toggler {
            .icon * {
                fill: $navy;
            }
        }
       
    }
}



.navbar-toggler-close {
    top: 15px;
}
.navbar-collapse {
    overflow-y: hidden !important;
    overflow: hidden !important;
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        -webkit-transform: translateY(0);
        transform:translateY(0);
  }
	40% {
    transform:translateY(-10px);
  }
	60% {
    transform:translateY(-5px);
  }
}




.downarrow {
    position:absolute;
    bottom: 80px;
    left: 50%;
    margin-left: -24px;
    animation: bounce 2s infinite;
    .down-arrow {
        -webkit-transform: rotateZ(270deg);
        transform: rotateZ(270deg);
        background: rgba($dbeige, .2);
        border: rgba($dbeige, .2);
        &:hover {
            background: $dbeige;
        }
        &:after{
            background-image: url("data:image/svg+xml;utf8,<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M14.8517 18.1136C14.9506 18.2121 15 18.3402 15 18.4977C15 18.6356 14.9506 18.7538 14.8517 18.8523C14.7331 18.9508 14.6045 19 14.4661 19C14.3277 19 14.209 18.9508 14.1102 18.8523L8.14831 12.8841C8.04944 12.7659 8 12.6379 8 12.5C8 12.3621 8.04944 12.2341 8.14831 12.1159L14.1102 6.14773C14.209 6.04924 14.3277 6 14.4661 6C14.6045 6 14.7331 6.04924 14.8517 6.14773C14.9506 6.24621 15 6.37424 15 6.53182C15 6.6697 14.9506 6.78788 14.8517 6.88636L9.42373 12.5L14.8517 18.1136Z' fill='%23FFFFFF'/></svg>");
        }
        &:hover:after {
             background-image: url("data:image/svg+xml;utf8,<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M14.8517 18.1136C14.9506 18.2121 15 18.3402 15 18.4977C15 18.6356 14.9506 18.7538 14.8517 18.8523C14.7331 18.9508 14.6045 19 14.4661 19C14.3277 19 14.209 18.9508 14.1102 18.8523L8.14831 12.8841C8.04944 12.7659 8 12.6379 8 12.5C8 12.3621 8.04944 12.2341 8.14831 12.1159L14.1102 6.14773C14.209 6.04924 14.3277 6 14.4661 6C14.6045 6 14.7331 6.04924 14.8517 6.14773C14.9506 6.24621 15 6.37424 15 6.53182C15 6.6697 14.9506 6.78788 14.8517 6.88636L9.42373 12.5L14.8517 18.1136Z' fill='%238091AA'/></svg>");
        }
    }
    a:after {
        display: none;
    }
}




.slider-nav {
    width: 100%;
    margin: 0px;
    padding: 0px;
    .nav-item {
        display: inline-block;
        margin: 0 10px;
        .nav-link {
            text-transform: uppercase;
            font-weight: 700;
            font-size: 15px;
            letter-spacing: 1px;
            color: $white;
            text-shadow: 1px 1px 3px rgba($black, .3);
            &.active, &:hover {
                color: $lblue;
            }
        }
    }
}

.slider-text {
    position: absolute;
    top: 0px;
    left: 50%;
    z-index: 10;
    transform: translateX(-50%);
    h1 {
        color: $white;
        font-weight: 400;
        text-shadow: 2px 2px 5px rgba($black, .3);
    }
}

section.has-divider .container {
    padding: 0px;
}
#contact {
    margin-top: 1px;
}

.has-divider {
    .col:after {
        content:'';
        display: inline-block;
        position:absolute;
        top: 32px;
        left: 0px;
        width: 100%;
        height: 1px;
        background-color: $lblue;
    }
    .divider {
        background-image: url('../img/dolphin-line.png');
        height: 65px;
        width: 65px;
        background-repeat: no-repeat;
        left: 50%;
        transform: translateX(-50%);
        background-size: 65px 65px;
        position: relative;
        z-index: 2;
    }
}

.controls-inside {
    .flickity-page-dots { 
        display: none;
    }
}



/*** FLICKETING CONTROLS *****/
.controls-light {
    .flickity-page-dots {
        z-index: 11;
        .dot {
            width: 6px;
            height: 6px;
            background: $beige !important;
            margin: 0 10px;
            opacity: 1;
            &.is-selected {
                background: $navy !important;
            }
            &:hover {
                background: $lblue !important;
            }
        }
    }

    .flickity-prev-next-button {
        background: rgba($dbeige, .2);
        border: 0px;
        fill: $dbeige;
    }
    .flickity-button:focus{
       box-shadow: 0 0 0 3px $beige;
    }

    .flickity-prev-next-button {
        &:after{
            background-image: url("data:image/svg+xml;utf8,<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M14.8517 18.1136C14.9506 18.2121 15 18.3402 15 18.4977C15 18.6356 14.9506 18.7538 14.8517 18.8523C14.7331 18.9508 14.6045 19 14.4661 19C14.3277 19 14.209 18.9508 14.1102 18.8523L8.14831 12.8841C8.04944 12.7659 8 12.6379 8 12.5C8 12.3621 8.04944 12.2341 8.14831 12.1159L14.1102 6.14773C14.209 6.04924 14.3277 6 14.4661 6C14.6045 6 14.7331 6.04924 14.8517 6.14773C14.9506 6.24621 15 6.37424 15 6.53182C15 6.6697 14.9506 6.78788 14.8517 6.88636L9.42373 12.5L14.8517 18.1136Z' fill='%23FFFFFF'/></svg>");
        }
        &:hover:after {
             background-image: url("data:image/svg+xml;utf8,<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M14.8517 18.1136C14.9506 18.2121 15 18.3402 15 18.4977C15 18.6356 14.9506 18.7538 14.8517 18.8523C14.7331 18.9508 14.6045 19 14.4661 19C14.3277 19 14.209 18.9508 14.1102 18.8523L8.14831 12.8841C8.04944 12.7659 8 12.6379 8 12.5C8 12.3621 8.04944 12.2341 8.14831 12.1159L14.1102 6.14773C14.209 6.04924 14.3277 6 14.4661 6C14.6045 6 14.7331 6.04924 14.8517 6.14773C14.9506 6.24621 15 6.37424 15 6.53182C15 6.6697 14.9506 6.78788 14.8517 6.88636L9.42373 12.5L14.8517 18.1136Z' fill='%238091AA'/></svg>");
        }
    }
}

.controls-dark {
    .flickity-page-dots {
        .dot {
            background: $dbeige !important;
             width: 6px;
            height: 6px;

            margin: 0 10px;
            opacity: 1;
            &.is-selected {
                background: $navy !important;
            }
            &:hover {
                background: $lblue !important;
            }
        }
    }
    .flickity-prev-next-button {
        border-color: $dbeige;
        background: $beige;
        &:hover {
            background: $white;
        }
        &.previous {
            left: -24px;
        }
        &.next {
            right: -24px;
        }
    }
}
/*** FLICKETING CONTROLS *****/



.shadow-tile {
    box-shadow: $box-shadow-sm;
}



footer {
    p.copyright {
        font-size: 10px;
        color: $lblue;
        a {
            color: $lblue;
            &:after {
                top: 14px;
            }
        }
    }
}

.back-to-top.btn-primary {
    border: $dbeige;
    background: $dbeige;
    &:hover, &:focus {
        border: $lblue;
        background: $lblue;
    }
}
@include media-breakpoint-down(md) {
    h1 {
    font-size: 30px;
    }
    h2 {
    font-size: 24px;
    line-height: 30px;
    }
    h3 {
        font-size: 18px;
    }
    h5 {
        font-size: 10px;
    }

    nav.navbar .nav-link {
        padding: 0px !important;
    }
    .has-divider .col:after {
        width: calc(100% - 30px);
        left: 15px;
    }
    nav.navbar .navbar-toggler {display: none;}
    nav.navbar.scrolled .navbar-toggler {display: inline-block;}

    nav.navbar .col-logo.justify-content-between {
        justify-content: center !important;
    }
    nav.navbar.scrolled .col-logo.justify-content-between {
        justify-content: space-between !important;
    }

    nav.navbar.scrolled .menu2 {
        margin-bottom: 15px;
    }

}

@include media-breakpoint-down(sm) {
    p {
    font-size: 14px;
    line-height: 20px;
    }
    .slider-nav {
        .nav-item {
            margin: 0px 10px;
            .nav-link {
                font-size: 12px;
                padding: 0px;
            }
        }
    }

    nav.navbar {
        .nav-link {
            font-size: 18px;
            line-height: 30px;
            margin-left: 7px;
        }
    }



}

