// 
// 
// user-variables.scss
//
// Place your own variable overrides here, these will override any Bootstrap and theme variables.



$bodycopy:  azo-sans-web, Helvetica, Arial, sans-serif;
$headings:  azo-sans-web, Helvetica, Arial, sans-serif;

$white:     #FFFFFF;
$black:     #000000;
$lgrey:     #CCCCCC;

$navy:      #203963;
$lblue:        #8091AA;
$beige:        #FCFBF8;
$dbeige:    #F3EEE1;



// Color Scheme
// Uncomment the variables below and change the hex values.

$primary:      $navy;
$primary-2:     $lblue;
$primary-3:     $beige;

// Do not change or remove the $theme-color-scheme or $theme-colors maps below

$theme-color-scheme: (
    "primary":    $primary,
    "primary-2":  $primary-2,
    "primary-3":  $primary-3
);

$theme-colors: (
    "primary":    $primary,
    "primary-2":  $primary-2,
    "primary-3":  $primary-3,
);
